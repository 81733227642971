<template>
  <div class="myfileheader">
    <div class="searchBar">
      <!-- 左侧部分 -->
      <div class="header_filter">
        <el-input
          v-model="search_box"
          ref="searchBox"
          :placeholder="$t('file.top.search')"
          style="width: 160px"
          size="mini"
          suffix-icon="el-icon-search"
          @keyup.enter.native="fuzzy_search"
          @blur="fuzzy_search"
        ></el-input>
        <!-- 未收藏 -->
        <el-tooltip
          v-if="!isShoucangChecked"
          effect="dark"
          :content="$t('file.table.options.collectList')"
          placement="bottom"
        >
          <i
            class="iconfont icon-shoucang hover-icon"
            @click="toggleShoucang"
          />
        </el-tooltip>
        <!-- 已收藏 -->
        <i
          v-else
          class="iconfont icon-shoucangClick hover-icon"
          style="margin-left: 20px; color: rgb(255, 195, 0); cursor: pointer"
          @click="toggleShoucang"
        />
        <!-- 类型下拉框 -->
        <span>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            :class="{ ischecked: checkedtypes.length }"
            style="cursor: pointer"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("file.top.type")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 类型搜索框 -->
                <el-input
                  :placeholder="$t(`file.top.type`)"
                  class="search_box"
                  v-model="searchKey.searchTypeKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAllTypes"
                  class="checkbox"
                  :indeterminate="isIndeterminateTypes"
                  @change="handleCheckAllChange($event, 'types')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="checkedtypes"
                  class="select"
                  :style="{
                    height: filteredTypeList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('type')"
                >
                  <el-checkbox
                    v-for="(type, index) in filteredTypeList"
                    :key="index"
                    :label="type.fileTypeId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ type.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 创建人下拉框 -->
        <span>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            :class="{ ischecked: checkedresponsible.length }"
            style="cursor: pointer"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("file.top.creator")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 创建人搜索框 -->
                <el-input
                  :placeholder="$t(`file.top.creator`)"
                  class="search_box"
                  v-model="searchKey.searchCreatorKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAllPerson"
                  class="checkbox"
                  :indeterminate="isIndeterminatePerson"
                  @change="handleCheckAllChange($event, 'person')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="checkedresponsible"
                  class="select"
                  :style="{
                    height:
                      filteredCreatorList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('person')"
                >
                  <el-checkbox
                    v-for="(res, index) in filteredCreatorList"
                    :key="index"
                    :label="res.accountId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ res.nickname }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 状态下拉框 -->
        <span>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            :class="{ ischecked: statusFiltered.length }"
            style="cursor: pointer"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("file.top.status")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 状态搜索框 -->
                <el-input
                  :placeholder="$t(`file.top.status`)"
                  class="search_box"
                  v-model="searchKey.searchStatusKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAllStatus"
                  class="checkbox"
                  :indeterminate="isIndeterminateStatus"
                  @change="handleCheckAllChange($event, 'status')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="statusFiltered"
                  class="select"
                  :style="{
                    height: filteredStatusList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('status')"
                >
                  <el-checkbox
                    v-for="(res, index) in filteredStatusList"
                    :key="index"
                    :label="res.statusId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ res.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 清除筛选条件 -->
        <span
          style="color: rgba(1, 76, 170, 1); cursor: pointer"
          @click="clearAllCondition"
        >
          <i class="iconfont icon-reverse1 link-font-color"> </i>&nbsp;{{
            $t("file.top.clearBtn")
          }}
        </span>
      </div>
      <!-- 右侧筛选部分 -->
      <div class="right_buttons">
        <span
          class="blue_button link-font-color"
          style="float: right"
          @click="savechecked"
        >
          {{ text_construction_map[text_construction] }}
        </span>
        <span
          v-if="text_construction === 1"
          class="blue_button"
          style="float: right"
          @click="dialogVisible = true"
        >
          {{ $t("file.top.anoSaveBtn") }}
        </span>
        <!-- 右侧筛选下拉框 -->
        <span class="filter_wrap" style="float: right">
          <el-select
            ref="filter_dialog"
            v-model="filter"
            clearable
            filterable
            :placeholder="$t('file.top.select')"
            size="mini"
            class="right_input"
            @visible-change="update_filter"
            @change="choose_filter"
            @clear="clear_filter"
          >
            <el-option
              v-for="item in filteroptions"
              :key="item.queryConditionId"
              :label="item.name"
              :value="item.queryConditionId"
            >
            </el-option>
            <el-option
              v-for="item in other_options"
              v-show="false"
              :key="item.queryConditionId"
              :label="item.name"
              :value="item.queryConditionId"
            >
            </el-option>
            <el-option :label="$t('file.top.viewAll')" value=" ">
              <div
                style="width: 100%; color: #336690"
                @click.stop="
                  more_condition_dialog = true;
                  currentPage = 1;
                "
              >
                <span>{{ $t("file.top.viewAll") }}</span>
              </div>
            </el-option>
          </el-select>
        </span>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="25%"
      :title="$t('node.top.confirmForm.title')"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-input
        v-model="input_name"
        :placeholder="$t('file.top.confirmForm.placeholder')"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="dialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="save_condition">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('file.dialog.condition')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="more_condition_dialog"
    >
      <el-table
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
        :data="
          all_options.slice(
            (currentPage - 1) * dialog_pagesize,
            currentPage * dialog_pagesize
          )
        "
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          :label="$t('file.dialog.name')"
          min-width="300"
        >
          <template slot-scope="scope">
            <span
              v-if="editing_row.queryConditionId !== scope.row.queryConditionId"
              style="cursor: pointer"
              @click="click_condition(scope)"
              @dblclick="edit_row(scope)"
              >{{ scope.row.name }}</span
            >
            <el-input
              v-else
              v-model="scope.row.name"
              autofocus
              size="mini"
              @blur="rename_condition(scope)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          :label="$t('file.dialog.creator')"
          min-width="130"
        >
          <template slot-scope="scope">
            <span>{{ name_span(scope.row.createdBy) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('file.dialog.option')">
          <template slot-scope="scope">
            <span
              v-if="scope.row.createdBy === userAccountId"
              type="text"
              size="small"
              style="color: #f56c6c; cursor: pointer"
              @click="delete_row(scope)"
              >{{ $t("btn.deleteBtn") }}</span
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :current-page="currentPage"
          :total="all_options.length"
          :page-size="dialog_pagesize"
          @current-change="dialog_current_change"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import {
  condition_get,
  people_get,
  get_filetype,
  save_condition,
  condition_delete,
  condition_change,
  condition_rename,
} from "@/network/home/index.js";
import { mapGetters } from "vuex";
export default {
  //   props: ["file_types"],
  props: ["statusList", "keySort", "sortBy"],
  data() {
    return {
      statusFiltered: [],
      isShoucangChecked: false,
      filter: "", //筛选当前项
      checkedtypes: [], //选择的文件类型
      // 全选相关
      checkAllTypes: false,
      isIndeterminateTypes: false,
      checkAllPerson: false,
      isIndeterminatePerson: false,
      checkAllStatus: false,
      isIndeterminateStatus: false,
      file_types: [], // 全部文件类型
      checkedresponsible: [], //选择的责任人
      responsible: [], // 全部责任人
      responsibleFilter: [], //过滤后的责任人
      //
      checkedaa: [], //更多
      aa: [{ name: "wyf" }, { name: "王宇帆" }], // 更多
      //
      options: [], // 自己创建的筛选条件
      all_options: [], // 包含其他人创建的筛选条件
      other_options: [],
      filteroptions: [],
      search_box: "",
      value: "",
      input_name: "",
      dialogVisible: false,
      isChange: false,
      more_condition_dialog: false,
      id_name_map: new Map(), // id与name的映射
      dialog_pagesize: 10, //指定展示多少条
      dialog_total: 0, //指定展示多少条
      currentPage: 1, //当前页码
      editing_row: {},
      time_out: null,
      conditions: [],
      checked_filter: {},
      text_construction_map: [
        this.$t("file.top.createBtn"),
        this.$t("file.top.saveBtn"),
        this.$t("file.top.anoSaveBtn"),
      ],
      searchKey: {
        searchTypeKey: "",
        searchCreatorKey: "",
        searchStatusKey: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user_list"]),
    // 类型选项列表
    filteredTypeList() {
      if (this.searchKey.searchTypeKey === "") {
        return this.file_types;
      }
      return this.file_types.filter(
        (item) => item.name.indexOf(this.searchKey.searchTypeKey) >= 0
      );
    },
    // 创建人选项列表
    filteredCreatorList() {
      if (this.searchKey.searchCreatorKey === "") {
        return this.responsibleFilter;
      }
      return this.responsibleFilter.filter(
        (item) => item.nickname.indexOf(this.searchKey.searchCreatorKey) >= 0
      );
    },
    // 状态选项列表
    filteredStatusList() {
      if (this.searchKey.searchStatusKey === "") {
        return this.statusList;
      }
      return this.statusList.filter(
        (item) => item.name.indexOf(this.searchKey.searchStatusKey) >= 0
      );
    },
    text_construction() {
      if (this.checked_filter && this.checked_filter.name) {
        const flag = this.filteroptions.some((item) => {
          return item.queryConditionId === this.checked_filter.queryConditionId;
        });
        return flag ? 1 : 2;
      } else {
        return 0;
      }
    },
    userAccountId() {
      return this.$store.getters.userAccountId;
    },
  },
  watch: {
    "$route.params.projectId": {
      //监测color_id的变化
      handler() {
        this.clearAllCondition();
        this.get_filetypes(); //获取文件的类型
        this.get_responsibles();
      },
    },
    checkedresponsible: {
      handler: function () {},
      deep: true,
    },
  },
  mounted() {
    if (this.get_pid() != "") {
      this.get_filetypes(); //获取文件的类型
      this.get_responsibles();
    }
    if (this.$route.query.conditions || this.$route.query.fieldId) {
      this.data_s();
    }
  },
  methods: {
    data_s() {
      let all_opt = [];

      let fileTypeId_list = [];
      let createdBy_list = [];
      let status_list = [];

      if (this.$route.query.queryConditionId) {
        condition_get(this.get_pid()).then((res) => {
          res.forEach((item) => {
            all_opt = [...all_opt, ...item.fileQueryConditionDtoList];
          });
          for (let i in all_opt) {
            if (
              all_opt[i].queryConditionId == this.$route.query.queryConditionId
            ) {
              all_opt[i].conditions.forEach((ele) => {
                if (ele.name == "status") {
                  status_list = ele.value;
                }
                if (ele.name == "fileTypeId") {
                  fileTypeId_list = ele.value;
                }
                if (ele.name == "createdBy") {
                  createdBy_list = ele.value;
                }
              });
              break;
            }
          }

          if (this.$route.query.conditions) {
            // 二维数据
            JSON.parse(this.$route.query.conditions).forEach((ele) => {
              if (ele.fieldId == "status") {
                this.statusList.forEach((ite) => {
                  if (
                    ite.name == this.$route.query.value ||
                    ite.statusId == this.$route.query.value
                  ) {
                    status_list.push(ite.statusId);
                  }
                });
              }
              if (ele.fieldId == "file_type_id") {
                fileTypeId_list.push(ele.value);
              }
              if (ele.fieldId == "created_by") {
                createdBy_list.push(ele.value);
              }
            });
          } else {
            if (this.$route.query.fieldId == "status") {
              this.statusList.forEach((ite) => {
                if (
                  ite.name == this.$route.query.value ||
                  ite.statusId == this.$route.query.value
                ) {
                  status_list.push(ite.statusId);
                }
              });
            }
            if (this.$route.query.fieldId == "file_type_id") {
              fileTypeId_list.push(this.$route.query.value);
            }
            if (this.$route.query.fieldId == "created_by") {
              createdBy_list.push(this.$route.query.value);
            }
          }

          status_list = [...new Set(status_list)];
          fileTypeId_list = [...new Set(fileTypeId_list)];
          createdBy_list = [...new Set(createdBy_list)];

          let data = [];

          status_list.length > 0
            ? data.push({ name: "status", value: status_list })
            : "";
          createdBy_list.length > 0
            ? data.push({ name: "createdBy", value: createdBy_list })
            : "";
          fileTypeId_list.length > 0
            ? data.push({ name: "fileTypeId", value: fileTypeId_list })
            : "";
          vmson.$emit("condition_update_data", data);

          this.checkAllPerson = true;
          this.isIndeterminatePerson = true;
          if (this.responsibleFilter.length == createdBy_list.length) {
            this.isIndeterminatePerson = false;
          }
          if (createdBy_list.length == 0) {
            this.isIndeterminatePerson = false;
            this.checkAllPerson = false;
          }
          this.checkedresponsible = createdBy_list;

          this.checkAllTypes = true;
          this.isIndeterminateTypes = true;
          if (this.file_types.length == fileTypeId_list.length) {
            this.isIndeterminateTypes = false;
          }
          if (fileTypeId_list.length == 0) {
            this.isIndeterminateTypes = false;
            this.checkAllTypes = false;
          }
          this.checkedtypes = fileTypeId_list;

          this.checkAllStatus = true;
          this.isIndeterminateStatus = true;
          if (this.statusList.length == status_list.length) {
            this.isIndeterminateStatus = false;
          }
          if (status_list.length == 0) {
            this.isIndeterminateStatus = false;
            this.checkAllStatus = false;
          }
          this.statusFiltered = status_list;
        });
      } else {
        if (this.$route.query.conditions) {
          // 二维数据
          JSON.parse(this.$route.query.conditions).forEach((ele) => {
            if (ele.fieldId == "status") {
              this.statusList.forEach((ite) => {
                if (
                  ite.name == this.$route.query.value ||
                  ite.statusId == this.$route.query.value
                ) {
                  status_list.push(ite.statusId);
                }
              });
            }
            if (ele.fieldId == "file_type_id") {
              fileTypeId_list.push(ele.value);
            }
            if (ele.fieldId == "created_by") {
              createdBy_list.push(ele.value);
            }
          });
        } else {
          if (this.$route.query.fieldId == "status") {
            this.statusList.forEach((ite) => {
              if (
                ite.name == this.$route.query.value ||
                ite.statusId == this.$route.query.value
              ) {
                status_list.push(ite.statusId);
              }
            });
          }
          if (this.$route.query.fieldId == "file_type_id") {
            fileTypeId_list.push(this.$route.query.value);
          }
          if (this.$route.query.fieldId == "created_by") {
            createdBy_list.push(this.$route.query.value);
          }
        }

        status_list = [...new Set(status_list)];
        fileTypeId_list = [...new Set(fileTypeId_list)];
        createdBy_list = [...new Set(createdBy_list)];
        let data = [];

        status_list.length > 0
          ? data.push({ name: "status", value: status_list })
          : "";
        createdBy_list.length > 0
          ? data.push({ name: "createdBy", value: createdBy_list })
          : "";
        fileTypeId_list.length > 0
          ? data.push({ name: "fileTypeId", value: fileTypeId_list })
          : "";
        vmson.$emit("condition_update_data", data);

        this.checkAllPerson = true;
        this.isIndeterminatePerson = true;
        if (this.responsibleFilter.length == createdBy_list.length) {
          this.isIndeterminatePerson = false;
        }
        if (createdBy_list.length == 0) {
          this.isIndeterminatePerson = false;
          this.checkAllPerson = false;
        }
        this.checkedresponsible = createdBy_list;

        this.checkAllTypes = true;
        this.isIndeterminateTypes = true;
        if (this.file_types.length == fileTypeId_list.length) {
          this.isIndeterminateTypes = false;
        }
        if (fileTypeId_list.length == 0) {
          this.isIndeterminateTypes = false;
          this.checkAllTypes = false;
        }
        this.checkedtypes = fileTypeId_list;

        this.checkAllStatus = true;
        this.isIndeterminateStatus = true;
        if (this.statusList.length == status_list.length) {
          this.isIndeterminateStatus = false;
        }
        if (status_list.length == 0) {
          this.isIndeterminateStatus = false;
          this.checkAllStatus = false;
        }
        this.statusFiltered = status_list;
      }
    },
    click_condition(scope) {
      clearTimeout(this.time_out);
      this.time_out = setTimeout(() => {
        this.filter = scope.row.name;
        this.more_condition_dialog = false;
        this.choose_filter(scope.row.queryConditionId);
      }, 300);
    },
    dialog_current_change(currentPage) {
      this.currentPage = currentPage;
    },
    rename_condition(scope) {
      this.editing_row = "";
      condition_rename(
        this.get_pid(),
        scope.row.queryConditionId,
        scope.row.name
      )
        .then((res) => {
          this.$message({
            type: "success",
            message: this.$t("chart.message.saveSuccess"),
          });
        })
        .catch(() => {});
    },
    edit_row(scope) {
      clearTimeout(this.time_out);
      const flag = this.filteroptions.some((item) => {
        return item.queryConditionId === scope.row.queryConditionId;
      });
      flag ? (this.editing_row = scope.row) : "";
    },
    delete_row(scope) {
      condition_delete(this.get_pid(), scope.row.queryConditionId)
        .then((res) => {
          this.all_options.splice(scope.$index, 1);
          if (scope.row.queryConditionId === this.filter) {
            this.filter = "";
            this.clear_filter();
            this.choose_filter("");
          }
          this.$message({
            type: "success",
            message: this.$t("addMyFileHead.info"),
          });
        }) //删除文件
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("addMyFileHead.info2"),
          });
        });
    },
    name_span(id) {
      return this.matchUserNickname(id, "");
    },
    clearAllCondition() {
      this.search_box = "";
      this.checkedtypes = [];
      this.checkedresponsible = [];
      this.statusFiltered = [];
      this.filter = "";
      this.checked_filter = {};
      this.checkAllTypes = false;
      this.checkAllPerson = false;
      this.checkAllStatus = false;
      this.isIndeterminateTypes = false;
      this.isIndeterminatePerson = false;
      this.isIndeterminateStatus = false;
      vmson.$emit("condition_update", []);
    },
    clearCondition() {
      this.search_box = "";
      this.checkedtypes = [];
      this.checkedresponsible = [];
      this.statusFiltered = [];
      this.filter = "";
      vmson.$emit("clear_condition", []);
    },
    toggleShoucang() {
      this.isShoucangChecked = !this.isShoucangChecked;
      this.clearCondition();
      vmson.$emit("getUserCollection", this.isShoucangChecked);
    },
    clear_filter() {
      this.filter = "";
      this.conditions = [];
      this.search_box = "";
      this.checkedtypes = [];
      this.checkedresponsible = [];
      this.statusFiltered = [];
      this.checkAllTypes = false;
      this.isIndeterminateTypes = false;
      this.checkAllPerson = false;
      this.isIndeterminatePerson = false;
      this.checkAllStatus = false;
      this.isIndeterminateStatus = false;
      vmson.$emit("clear_condition", []);
    },
    choose_filter(id) {
      let obj = [];
      this.checkedtypes = [];
      this.checkedresponsible = [];
      this.statusFiltered = [];
      this.search_box = "";
      this.checked_filter = this.all_options.filter((item) => {
        if (item.queryConditionId === id) {
          this.conditions = item.conditions;
        }
        return item.queryConditionId === id;
      })[0];
      for (let i in this.all_options) {
        if (this.all_options[i].queryConditionId == id) {
          for (let j in this.all_options[i].conditions) {
            if (this.all_options[i].conditions[j].name == "fileTypeId") {
              obj.push({
                name: "fileTypeId",
                value: this.all_options[i].conditions[j].value,
              });
              this.isIndeterminateTypes =
                this.all_options[i].conditions[j].value.length !==
                this.file_types.length;
              this.checkAllTypes =
                this.all_options[i].conditions[j].value.length ===
                this.file_types.length;
              this.checkedtypes.push(
                ...this.all_options[i].conditions[j].value
              );
            }
            if (this.all_options[i].conditions[j].name == "createdBy") {
              obj.push({
                name: "createdBy",
                value: this.all_options[i].conditions[j].value,
              });
              this.isIndeterminatePerson =
                this.all_options[i].conditions[j].value.length !==
                this.responsibleFilter.length;
              this.checkAllPerson =
                this.all_options[i].conditions[j].value.length ===
                this.responsibleFilter.length;
              this.checkedresponsible.push(
                ...this.all_options[i].conditions[j].value
              );
            }

            if (this.all_options[i].conditions[j].name == "status") {
              obj.push({
                name: "status",
                value: this.all_options[i].conditions[j].value,
              });
              this.isIndeterminateStatus =
                this.all_options[i].conditions[j].value.length !==
                this.statusList.length;
              this.checkAllStatus =
                this.all_options[i].conditions[j].value.length ===
                this.statusList.length;
              this.statusFiltered.push(
                ...this.all_options[i].conditions[j].value
              );
            }
          }
          this.search_box = this.all_options[i].fuzzyKey;
        }
      }
      vmson.$emit("condition_update", obj, this.search_box);
    },

    // 模糊查询
    fuzzy_search() {
      this.$refs.searchBox.blur();
      vmson.$emit("fuzzy_search", this.search_box);
    },

    filt_data(name) {
      let conditions = [];
      this.conditions = [];
      if (this.checkedtypes.length) {
        let obj = {
          name: "fileTypeId",
          value: this.checkedtypes,
        };
        conditions.push(obj);
      }
      if (this.checkedresponsible.length) {
        let obj = {
          name: "createdBy",
          value: this.checkedresponsible,
        };
        conditions.push(obj);
      }
      if (this.statusFiltered.length) {
        let obj = {
          name: "status",
          value: this.statusFiltered,
        };
        conditions.push(obj);
      }
      this.conditions = conditions;
      vmson.$emit("condition_update", conditions, this.search_box);
    },

    update_status() {
      this.filt_data("status");
    },

    update_type() {
      this.filt_data("fileTypeId");
    },
    update_person() {
      this.filt_data("createdBy");
    },
    update_filter(flag) {
      if (flag) {
        this.all_options = [];
        this.other_options = [];
        this.filteroptions = [];
        condition_get(this.get_pid()).then((res) => {
          res.forEach((item) => {
            if (item.createdBy === this.userAccountId) {
              this.filteroptions = [...item.fileQueryConditionDtoList];
              this.all_options = [
                ...item.fileQueryConditionDtoList,
                ...this.all_options,
              ];
            } else {
              this.other_options = [
                ...this.other_options,
                ...item.fileQueryConditionDtoList,
              ];
              this.all_options = [
                ...this.all_options,
                ...item.fileQueryConditionDtoList,
              ];
            }
          });
        });
      }
    },
    save_condition() {
      if (this.input_name !== "") {
        let condition = [];
        let list = [];
        for (let i in this.checkedtypes) {
          list.push(this.checkedtypes[i]);
        }
        if (list.length > 0) {
          let obj = {
            name: "fileTypeId",
            value: list,
          };
          condition.push(obj);
        }

        list = [];
        for (let i in this.checkedresponsible) {
          list.push(this.checkedresponsible[i]);
        }
        if (list.length > 0) {
          let obj = {
            name: "createdBy",
            value: list,
          };
          condition.push(obj);
        }

        list = [];
        for (const status of this.statusFiltered) {
          list.push(status);
        }
        if (list.length > 0) {
          let obj = {
            name: "status",
            value: list,
          };
          condition.push(obj);
        }
        save_condition(
          this.keySort,
          this.input_name,
          this.get_pid(),
          this.sortBy,
          condition,
          this.search_box
        ).then((id) => {
          this.dialogVisible = false;
          this.$nextTick(() => {
            this.all_options = [];
            this.other_options = [];
            this.filteroptions = [];
            condition_get(this.get_pid()).then((res) => {
              res.forEach((item) => {
                if (item.createdBy === this.userAccountId) {
                  this.filteroptions = [...item.fileQueryConditionDtoList];
                  this.all_options = [
                    ...item.fileQueryConditionDtoList,
                    ...this.all_options,
                  ];
                } else {
                  this.other_options = [
                    ...this.other_options,
                    ...item.fileQueryConditionDtoList,
                  ];
                  this.all_options = [
                    ...this.all_options,
                    ...item.fileQueryConditionDtoList,
                  ];
                }
              });
              this.filter = id;
              this.choose_filter(id);
            });
          });
          this.$message({
            message: this.$t("addMyFileHead.info3"),
            type: "success",
          });
        });
      } else {
        this.$message({
          message: this.$t("addMyFileHead.info4"),
          type: "error",
        });
      }
    },

    async isHide_types(status) {
      //文件类型选择框出现消失;
      if (status == true) {
        if (this.file_types.length == 0) this.get_filetypes(); //确保有文件类型
        return;
      }
    },
    async get_filetypes() {
      //获取文件类型
      try {
        if (!this.get_pid()) return;
        let res = await get_filetype(this.get_pid());
        this.file_types = res; //请求的结果传给file_types
      } catch (error) {
        console.log(error);
      }
    },
    async get_responsibles() {
      //获取创建人
      try {
        if (!this.get_pid()) return;
        let res = await people_get(this.get_pid());
        this.responsible = res; //请求的结果传递
        this.responsibleFilter = this.responsible.filter(
          (user) => user.deleted === 0
        );
      } catch (error) {
        console.log(error);
      }
    },
    handleCheckAllChange(val, suffix) {
      switch (suffix) {
        case "types":
          this.checkedtypes = val
            ? this.file_types.map((item) => {
                return item.fileTypeId;
              })
            : [];
          this.isIndeterminateTypes = false;
          this.update_type();
          break;
        case "person":
          this.checkedresponsible = val
            ? this.responsibleFilter.map((item) => {
                return item.accountId;
              })
            : [];
          this.isIndeterminatePerson = false;
          this.update_person();
          break;
        case "status":
          this.statusFiltered = val
            ? this.statusList.map((item) => {
                return item.statusId;
              })
            : [];
          this.isIndeterminateStatus = false;
          this.update_status();
          break;
      }
    },
    selecteFiletype(arg) {
      switch (arg) {
        case "type":
          this.checkAllTypes =
            this.checkedtypes.length === this.file_types.length;
          this.isIndeterminateTypes =
            this.checkedtypes.length > 0 &&
            this.checkedtypes.length < this.file_types.length;
          this.update_type();
          break;
        case "person":
          this.checkAllPerson =
            this.checkedresponsible.length === this.responsibleFilter.length;
          this.isIndeterminatePerson =
            this.checkedresponsible.length > 0 &&
            this.checkedresponsible.length < this.responsibleFilter.length;
          this.update_person();
          break;
        case "status":
          this.checkAllStatus =
            this.statusFiltered.length === this.statusList.length;
          this.isIndeterminateStatus =
            this.statusFiltered.length > 0 &&
            this.statusFiltered.length < this.statusList.length;
          this.update_status();
          break;
      }
    },
    //
    //
    savechecked() {
      //保存按钮的事件
      this.input_name = "";
      if (this.text_construction === 1) {
        condition_change(this.get_pid(), this.checked_filter.queryConditionId, {
          fuzzyKey: this.search_box,
          queryConditions: this.conditions,
          desc: this.keySort,
          sortby: this.sortBy,
        }).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("addMyFileHead.info3"),
          });
        });
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ischecked {
  ::v-deep .center_span {
    border: 1px solid #336690;
    background-color: #336690;
    color: #fff;
  }
}
.icon-shoucang {
  margin: 0 20px;
  cursor: pointer;
}
.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
}
::v-deep.search_box {
  align-self: start;
  height: 28px;
  width: 150px;
  margin: 2px 15px 2px 15px;
  .el-input__inner {
    height: 28px;
  }
}
.checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 10px;
}
.header_left {
  margin-left: 1%;
}
.header_input {
  width: 160px;
  height: 20px;
}

//
.herder_center {
  margin-top: 4px;
  & > div {
    margin: 0 10px;
    display: inline-block;
  }
}
.right_buttons {
  float: right;
  display: flex;
  align-items: center;
  & > span {
    margin: 0 10px !important;
  }
}
.center_span {
  background-color: #e5e5e5;
  padding: 6px 8px;
  font-size: 13px;
  border-radius: 4px;
  & > i {
    margin-left: 10px;
  }
}

.herder_center :nth-child(5) {
  cursor: pointer;
}
//
.blue_button {
  color: rgba(1, 76, 170, 1);
  border: 1px solid rgba(1, 76, 170, 1);
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  margin-right: 10px;
}
.header_right {
  display: flex;
  justify-content: space-between;
  ::v-deep .el-input__inner {
    background-color: #e5e5e5;
  }
}
.right_add {
  width: 80px;
  height: 18px;
  margin-top: -3px;
  cursor: pointer;
}
.right_input {
  width: 150px;
}

.myfileheader {
  .dialog-footer {
    display: flex;
    justify-content: right;
  }
  .dialogname {
    margin-top: 2px;
    display: flex;
    .el-input {
      width: 80% !important;
      margin-left: 20px;
    }
    span {
      margin-top: 6px;
      margin-left: 5px;
    }
  }
}
.searchBar {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .header_filter {
    flex: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > span {
      margin: 10px 10px;
    }

    .filter_tag {
      display: inline-block;
    }
  }
  .blue_button {
    color: rgba(1, 76, 170, 1);
    border: 1px solid rgba(1, 76, 170, 1);
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    margin-right: 0px;
    margin-top: 1px;
  }
  .right_input {
    width: 150px;
  }
}
</style>
<style lang="scss">
.select {
  .checkbox {
    margin: 0 15px 0 15px !important;
    width: 100%;
  }
}
</style>
